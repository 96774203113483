<div>
    <md-activity-booking
        [productDetails]="internalProductDetails"
        (bookingConfigured)="onBookingConfigured($event)"
        (quantityChanged)="onQuantityChanged($event)"
        [totalAllotment]="totalAllotment"
    />
    <div style="display: flex; justify-content: center; margin-top: 20px">
        <md-calendar
            #calendarDatedActivity
            [isSingleDate]="true"
            [infoForEachDay]="additionalCalendarDayInfo"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [errorMessage]="calendarErrorMessage"
        />
    </div>
    <div
        mdMobileOnly
        (click)="!calendarErrorMessage && calendarDatedActivity.openCalendar()"
    >
        <div
            *ngIf="calendarErrorMessage"
            class="error-message-container"
        >
            <div class="error-message">
                {{ calendarErrorMessage }}
            </div>
        </div>
        <div
            *ngIf="!calendarErrorMessage"
            class="pdbc-mobile-dates-container calendar-preview-dates-container"
        >
            <div class="pdbc-mobile-dates-split calendar-preview-dates-split">
                <div class="pdbc-mobile-dates-title">
                    {{ "Check-in" | translate }}
                </div>
                <div
                    class="pdbc-mobile-dates-content empty"
                    *ngIf="!formattedDate"
                >
                    -
                </div>
                <div
                    class="pdbc-mobile-dates-content"
                    *ngIf="formattedDate"
                >
                    {{ formattedDate }}
                </div>
            </div>
        </div>
    </div>
</div>