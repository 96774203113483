import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AgeGroup } from "src/app/model/deal.model";

@Component({
  selector: 'md-occupancy',
  templateUrl: './occupancy.component.html',
  styleUrls: ['./occupancy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OccupancyComponent {
  @Input()
  ageGroups: AgeGroup[];

  @Input()
  disableAllPlus = false;

  @Output()
  occupancyChanged = new EventEmitter<AgeGroup[]>();

  public onCounterChange(ageGroup: AgeGroup, qty: number) {
    ageGroup.qty = qty;
    this.occupancyChanged.emit(this.ageGroups);
  }
}