<div class="main-div">
    <div class="card new-ui">
        <div class="card-body">
            <!-- Start Email Login Form -->
            <div class="login-text" mdDesktopOnly>Welcome to MariDeal</div>
            <form
                [formGroup]="emailLoginForm"
                [ngClass]="['email-login-form']"
                *ngIf="showEmailForm"
            >
                <div class="form-group" *ngIf="!isPasswordFieldVisible">
                    <label
                        for="email"
                        [ngClass]="
                            emailLoginForm.value.email.length > 0
                                ? 'has-input'
                                : ''
                        "
                        >{{ "Email Address" | translate }}</label
                    >
                    <input
                        type="text"
                        id="email"
                        #email
                        formControlName="email"
                        (focusin)="emailErrorClass = ''"
                        (focusout)="validateEmail()"
                        [ngClass]="[
                            'form-control',
                            emailErrorClass,
                            formErrorClass
                        ]"
                        [placeholder]="'Email Address' | translate"
                    />
                </div>
                <div class="form-group" *ngIf="isPasswordFieldVisible">
                    <label
                        for="password"
                        [ngClass]="
                            emailLoginForm.value.password.length > 0
                                ? 'has-input'
                                : ''
                        "
                        >{{ "Password" | translate }}</label
                    >
                    <input
                        type="{{ showPassword ? 'text' : 'password' }}"
                        id="password"
                        #password
                        formControlName="password"
                        (focusin)="passwordErrorClass = ''"
                        (focusout)="validatePassword()"
                        [ngClass]="[
                            'form-control',
                            passwordErrorClass,
                            formErrorClass
                        ]"
                        [placeholder]="'Password' | translate"
                    />
                    <div
                        (click)="toggleShowPassword()"
                        [ngClass]="['fa', passwordClass, 'showandhidepass']"
                    ></div>
                </div>
                <div class="content-body" *ngIf="!isPasswordFieldVisible">
                    <div class="term-content form-group">
                        <span>
                            {{
                                "By clicking an option below, I agree to the"
                                    | translate
                            }}
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141319-terms-conditions"
                                target="_blank"
                                >{{ "Terms of Use" | translate }}</a
                            >
                            {{ "and have read the" | translate }}
                            <a
                                href="https://marideal.freshdesk.com/support/solutions/articles/153000141323-privacy-policy"
                                target="_blank"
                                >{{ "Privacy Statement" | translate }}</a
                            ></span
                        >
                    </div>
                    <div class="create-account form-group">
                        <a
                            (click)="navigateToRegister()"
                            class="create-account"
                            >{{ "Create an account" | translate }}</a
                        >
                    </div>
                </div>
                <div class="form-group">
                    <button
                        class="btn ripleEffect"
                        *ngIf="!isPasswordFieldVisible"
                        [disabled]="
                            !emailLoginForm.get('email')?.valid ||
                            (isPasswordFieldVisible &&
                                emailLoginForm.get('password')?.invalid)
                        "
                        (click)="onContinueClick()"
                        [ngClass]="{
                            'valid-email': emailLoginForm.get('email')?.valid,
                            'invalid-email': !emailLoginForm.get('email')?.valid
                        }"
                    >
                        {{ "Continue" | translate }}
                    </button>
                    <button
                        *ngIf="isPasswordFieldVisible"
                        class="btn ripleEffect"
                        (click)="onSubmitEmailLoginForm()"
                        type="submit"
                        [ngClass]="
                            emailLoginForm.value.email.length > 0
                                ? 'has-input'
                                : ''
                        "
                    >
                        {{ "Login" | translate }}
                    </button>
                </div>
            </form>
            <!-- End Email Login Form -->

            <div
                class="content-body forget-pass-new-acc"
                *ngIf="isPasswordFieldVisible"
            >
                <a
                    (click)="navigateToForgottenPassword()"
                    class="forgot-password"
                    >{{ "Reset password" | translate }}</a
                >
            </div>
            <div *ngIf="!isPasswordFieldVisible">
                <p class="or-seprator">Or</p>

                <div class="continue-with-box">
                    <button
                        class="btn btn-outline-secondary social-button ripleEffect"
                        *ngIf="showMobileForm"
                        (click)="showMobileForm = false; showEmailForm = true"
                    >
                        <div class="social-display">
                            <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.9995 4.7019L11.4052 9.98455C10.905 10.2747 10.0945 10.2547 9.6043 9.95453L1 4.7019"
                                    stroke="#092E41"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M15.9275 1H5.07204C2.82311 1 1 2.82311 1 5.07204V15.9275C1 18.1764 2.82311 19.9995 5.07204 19.9995H15.9275C18.1764 19.9995 19.9995 18.1764 19.9995 15.9275V5.07204C19.9995 2.82311 18.1764 1 15.9275 1Z"
                                    stroke="#092E41"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                />
                            </svg>
                            <div class="social-login">
                                {{ "Continue with Email" | translate }}
                            </div>
                        </div>
                    </button>
                </div>
                <div class="social-login-buttons">
                    <md-app-social-login></md-app-social-login>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #otpResent let-modal>
    <div class="modal-body">
        {{ "OTP resent successfully" | translate }}.
        <button
            type="button"
            class="btn btn-primary modalbtn"
            (click)="modal.close('Save click')"
            data-mdb-ripple-color="dark"
        >
            {{ "OK" | translate }}
        </button>
    </div>
</ng-template>
