import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DealInfo } from 'src/app/model/deal.model';
import { parseMarkdown } from 'src/app/utilities/markdown.utils';

@Component({
    selector: 'md-deal-information',
    templateUrl: './deal-information.component.html',
    styleUrls: ['./deal-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealInformationComponent {
    private _dealInformation: DealInfo;

    @Input()
    set dealInformation(value: DealInfo) {
        this._dealInformation = value;
        this.updateDealInformation();
    }

    purchaseIncludesMarkup: string | Promise<string>;
    packageDetailsMarkup: string | Promise<string>;
    aboutMarkup: string | Promise<string>;
    hasPackageDetails = false;
    hasAbout = false;

    private updateDealInformation(): void {
        if (!this._dealInformation) {
            this.purchaseIncludesMarkup = "No information found for this deal; please contact admin.";
            return;
        }

        const { about, purchaseIncludes, packageDetails } =
            this._dealInformation;
        this.aboutMarkup = parseMarkdown(about);
        this.purchaseIncludesMarkup = parseMarkdown(purchaseIncludes);
        this.packageDetailsMarkup = parseMarkdown(packageDetails);
        this.hasPackageDetails = !!packageDetails;
        this.hasAbout = !!about;
    }
}
