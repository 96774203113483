import { AgeGroup } from '@app/model/deal.model';
import { TranslateService } from '@ngx-translate/core';

export const getOccupancyLabelFromAgeGroups = (
    ageGroups: AgeGroup[],
    translate: TranslateService
): string => {
    if (!ageGroups || !ageGroups.length) return '';
    return ageGroups
        .filter((ageGroup) => ageGroup.qty > 0)
        .map((ageGroup) => {
            const qty = ageGroup.qty;
            let ageGroupType = ageGroup.ageGroupType;

            if (qty > 1) {
                if (ageGroupType === 'Child') ageGroupType = 'Children';
                else ageGroupType = `${ageGroupType}s`;
            }

            return `${qty} ${translate.instant('AgeGroup.' + ageGroupType)}`;
        })
        .join(' + ');
};
