import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum RewardsAndWalletErrors {
    GIFT_CARD_NOT_FOUND_OR_NOT_SENT_TO_CUSTOMER = 'gift-card-not-found-or-not-sent-to-customer',
    GIFT_CARD_SOMETHING_WENT_WRONG = 'something-went-wrong',
}
// Add your error types here

export interface RewardsAndWalletError {
    errorCode: RewardsAndWalletErrors;
    info: string;
}

@Injectable({
    providedIn: 'root',
})
export class RewardsAndWalletErrorService {
    constructor(private translate: TranslateService) {}

    getTranslatedErrorMessageFromErrorCode(
        error: RewardsAndWalletError
    ): string {
        const { errorCode } = error;
        return this.translate.instant(`profileErrors.${errorCode}`);
    }

    isKnownErrorCode(errorCode: string): boolean {
        return Object.values(RewardsAndWalletErrors).includes(
            errorCode as RewardsAndWalletErrors
        );
    }
}
