<div class="c-o-container">
    <div
        class="c-o-counter"
        *ngFor="let ageGroup of ageGroups"
    >
        <div class="c-o-text">
            <div class="c-o-text-name">{{ 'AgeGroup.' + ageGroup.ageGroupType | translate }}</div>
            <div class="c-o-text-description">
                {{ "Age" | translate }}
                <ng-container *ngIf="ageGroup.ageGroupType === 'Adult'; else elseBlock">
                    {{ ageGroup.ageFrom }}+
                </ng-container>
                <ng-template #elseBlock>
                    {{ ageGroup.ageFrom }} - {{ ageGroup.ageTo }}
                </ng-template>
            </div>
        </div>
        <md-counter
            [counterValue]="ageGroup.qty ?? 0"
            [maxValue]="ageGroup.maxPax ?? 30"
            [disablePlus]="disableAllPlus"
            (valueChanged)="onCounterChange(ageGroup, $event)"
        ></md-counter>
    </div>
</div>