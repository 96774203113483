import { Injectable } from '@angular/core';
import {
    AddCartItemDto,
    BookingConfiguredData,
    CartDto,
    DealInfoDto,
    DealOptionIdDto,
    ProductBookingData,
    ProductBookingDto,
    DealInfoWithMultiSelectOptionDto,
    DealInfoWithSingleSelectOptionDto,
    DealInfoWithAccomOptionDto,
} from './booking-config.model';
import { Observable } from 'rxjs';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import {
    addDaysToDateStruct,
    createDateFromString,
    createDateStringReverse,
    getTodayNgb,
} from 'src/app/controls/calendar/calendar.utils';
import { DealDto, DealOfferOption } from 'src/app/model/deal.model';
import { UserService } from 'src/app/services/user.service';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    isUserLoggedIn = false;

    constructor(
        private userService: UserService,
        private authHttpClient: AuthHttpClientNoXService
    ) {}

    addToCartV2(
        bookingConfigedData: BookingConfiguredData,
        productDetails: DealDto,
        currencyId: 1 | 2
    ): Observable<CartDto> {
        const apiUrl = this.userService.isLoggedIn
            ? `${getBookingUrl()}carts/items`
            : `${getBookingUrl()}carts/items/as-guest`;
        const localCart = this.userService.getLocalCart();
        // if there is a user cart use that, else use the local cart
        const cartId = localCart?.userCartId;
        const { checkinDate, checkoutDate, expiryDate } = this.assembleDates(
            bookingConfigedData,
            productDetails
        );
        const {
            productBookingData,
            totalPrice,
            totalFullPrice,
            rewardPointsEarned,
        } = bookingConfigedData;

        const postBody: AddCartItemDto = {
            cartId,
            outletId: productDetails.outletId,
            dealId: productBookingData?.id,
            dealCategoryId: productDetails.categoryId,
            sectionId: productDetails.categoryId,
            dealTitle: productDetails.dealTitle,
            dealSubtitle: productDetails.subTitle,
            rewardPointsEarned,
            quantity: 1,
            subtotal: totalPrice,
            purchaseCurrencyId: currencyId,
            grandTotal: totalFullPrice,
            dealInfo: this.createDealInfo(productBookingData, productDetails),
        };

        checkinDate &&
            (postBody.checkIn = createDateStringReverse(checkinDate));
        checkoutDate &&
            (postBody.checkOut = createDateStringReverse(checkoutDate));
        expiryDate &&
            (postBody.expiryDate = createDateStringReverse(expiryDate));

        return this.authHttpClient.post<CartDto, AddCartItemDto>(
            apiUrl,
            postBody
        );
    }

    private assembleDates(
        bookingConfigedData: BookingConfiguredData,
        productDetails: DealDto
    ): {
        checkinDate: NgbDateStruct;
        checkoutDate: NgbDateStruct;
        expiryDate: NgbDateStruct;
    } {
        const { checkinDate, checkoutDate, selectedDate } =
            bookingConfigedData.productBookingData;
        const dealExpiryDate = this.computeExpirationDate(productDetails);

        if (checkinDate && checkoutDate) {
            return {
                expiryDate: dealExpiryDate,
                checkinDate,
                checkoutDate,
            };
        }

        if (selectedDate) {
            return {
                expiryDate: dealExpiryDate,
                checkinDate: selectedDate,
                checkoutDate: selectedDate,
            };
        }

        return {
            expiryDate: dealExpiryDate,
            checkinDate: undefined,
            checkoutDate: undefined,
        };
    }

    private computeExpirationDate(productDetails: DealDto): NgbDateStruct {
        const today = getTodayNgb();
        if (productDetails.validityDays) {
            return addDaysToDateStruct(today, productDetails.validityDays);
        }
        if (productDetails.expirationDate) {
            const expirationdatedayjs = dayjs(productDetails.expirationDate);
            const expirationDateNgb = new NgbDate(
                expirationdatedayjs.year(),
                expirationdatedayjs.month() + 1,
                expirationdatedayjs.date()
            );
            if (expirationDateNgb.after(today)) {
                return expirationDateNgb;
            }
        }

        return undefined;
    }

    private createDealInfo(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        if (
            productData.selectedAccomOptions &&
            productData.selectedAccomOptions.length > 0
        ) {
            return this.createDealInfoWithAccomOption(
                productData,
                productDetails
            );
        } else if (
            productData.selectedDealOptions &&
            productData.selectedDealOptions.length > 0
        ) {
            return this.createSingleSelectDealInfoDto(
                productData,
                productDetails
            );
        } else if (
            productData.selectedDealOptionQuantities &&
            productData.selectedDealOptionQuantities.size > 0
        ) {
            return this.createMultiSelectDealInfoDto(
                productData,
                productDetails
            );
        } else {
            return '';
        }
    }

    private createSingleSelectDealInfoDto(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        const baseDealInfoDto = this.createBaseDealInfoDto(productDetails);
        const selectedDealOption = productData.selectedDealOptions[0];
        if (!selectedDealOption) {
            // Sentry.captureException(new Error('No deal option selected'));
            return '';
        }

        const otherDealInfoDto: DealInfoWithSingleSelectOptionDto = {
            ...baseDealInfoDto,
            dealOptionIds: [
                {
                    dealOptionId: selectedDealOption.dealOptionId,
                    quantity: 1,
                    dealOptionName: selectedDealOption.title,
                    subtotal: selectedDealOption.sellingPrice,
                },
            ],
        };
        return JSON.stringify(otherDealInfoDto);
    }
    private createDealInfoWithAccomOption(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        const baseDealInfoDto = this.createBaseDealInfoDto(productDetails);
        const [selectedAccomOption] = productData.selectedAccomOptions;

        const dealInfoWithAccomOptionDto: DealInfoWithAccomOptionDto = {
            ...baseDealInfoDto,
            roomId: selectedAccomOption.roomId,
            roomCategory: selectedAccomOption.roomName,
            mealPlan: selectedAccomOption.mealPlanType,
            mealPlanId: selectedAccomOption.mealPlanId,
            occupancyId: productData.occupancy?.occupancyId,
            occupancy: productData.occupancy?.occupancyLabel,
        };
        return JSON.stringify(dealInfoWithAccomOptionDto);
    }

    private createMultiSelectDealInfoDto(
        productData: ProductBookingData,
        productDetails: DealDto
    ): string {
        const baseDealInfoDto = this.createBaseDealInfoDto(productDetails);
        const quantitiesDealInfoDto: DealInfoWithMultiSelectOptionDto = {
            ...baseDealInfoDto,
            dealOptionIds: this.createDealOptionIdObject(
                productData.selectedDealOptionQuantities
            ),
        };
        return JSON.stringify(quantitiesDealInfoDto);
    }

    private createDealOptionIdObject(
        optionQuantities: Map<DealOfferOption, number>
    ): DealOptionIdDto[] {
        return Array.from(optionQuantities.entries())
            .map(([option, quantity]) => {
                return {
                    dealOptionId: option.dealOptionId,
                    quantity,
                    dealOptionName: option.title,
                    subtotal: option.sellingPrice * quantity,
                };
            })
            .filter((dealOption) => dealOption.quantity > 0);
    }

    private createBaseDealInfoDto(productDetails: DealDto): DealInfoDto {
        return {
            dealConditions: JSON.stringify(
                productDetails.staticContentV2.conditions
            ),
            packageTypeId: productDetails.packageTypeId,
            redeemLimit: productDetails.redeemLimit,
        };
    }

    private addMariBnbDataToProductDataDto(
        productData: ProductBookingData,
        productDto: ProductBookingDto
    ) {
        if (productData.mariBnbOccupancy) {
            productDto.adults = productData.mariBnbOccupancy.adults;
            productDto.children = productData.mariBnbOccupancy.children;
            productDto.infants = productData.mariBnbOccupancy.infants;
        }
    }

    private createAddonDataDto(productData: ProductBookingData) {
        return productData.addOnConfigurations
            ? productData.addOnConfigurations.map((addonConfig) => {
                  return {
                      id: addonConfig.addonItem.id,
                      price_type: addonConfig.addonItem.priceType,
                      sku: addonConfig.addonItem.sku,
                      title: addonConfig.addonItem.title,
                      qty: addonConfig.quantity,
                  };
              })
            : '';
    }
}
