<app-filter-data
    class="src-filters-container"
    (applyFilters)="applyFilters($event)"
    (sort)="applySorting($event)"
    [idsAlreadyAppliedFilters]="filterIdsFromQueryString2"
></app-filter-data>

<div
    infiniteScroll
    [infiniteScrollContainer]="scrollablePane"
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    [immediateCheck]="true"
    [infiniteScrollDisabled]="loading"
    [alwaysCallback]="true"
    (scrolled)="onScroll()"
    class="src-scroll-container"
>
    <md-category-sort-bar
        [showClosestDate]="false"
        [sortLabel]="sortLabel"
        (sort)="applySorting($event)"
        class="src-sort-bar"
    >
    </md-category-sort-bar>
    <!-- Loading Indicator -->
    <div *ngIf="loading">
        <md-product-list-item-loading *ngFor="let item of [].constructor(4)" />
    </div>
    <div class="err-box">
        <div
            *ngIf="errorMessage"
            class="src-error-message"
            [innerHtml]="errorMessage"
        ></div>
        <div
            *ngIf="errorContact"
            class="src-error-contact"
            [innerHtml]="errorContact"
        ></div>
        <!-- Search Results -->
        <div class="no-results" *ngIf="!loading">
            <div *ngIf="deals.length > 0">
                <div
                    *ngFor="let deal of deals; let i = index"
                    (click)="redirectToProductDetails(deal)"
                >
                    <md-product-list-item
                        [data]="deal"
                        [index]="index"
                    ></md-product-list-item>
                </div>
            </div>
        </div>
    </div>
</div>
