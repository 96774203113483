import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EGiftCardFormData, GiftCardValidation } from '../egift-card.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
    transformNgbDateToDayJs,
    createDateString,
} from 'src/app/controls/calendar/calendar.utils';

@Component({
    selector: 'md-gc-form',
    templateUrl: './gc-form.component.html',
    styleUrls: ['./gc-form.component.scss'],
})
export class GiftCardForm implements OnInit {
    @Input() selectedGiftCardImage: { name: string; path: string };
    @Input() validation: GiftCardValidation;
    @Input() initialValues: EGiftCardFormData;

    @Output() buyGiftCard = new EventEmitter<void>();
    @Output() formDataChanged = new EventEmitter<EGiftCardFormData>();

    giftCardForm: FormGroup;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    selectedDate: NgbDate;
    formattedDate = '-';
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    @ViewChild('calendarDatedActivity')
    calendarDatedActivity: CalendarComponent;
    startDate: NgbDateStruct;

    constructor(private currencyLanguageService: CurrencyLanguageService) {}

    ngOnInit() {
        this.giftCardForm = new FormGroup({
            value: new FormControl<number>(this.validation.giftCardAmount.min, [
                Validators.required,
                Validators.min(0),
                Validators.max(this.validation.giftCardAmount.max),
            ]),
            recipientName: new FormControl<string>('', [Validators.required]),
            deliveryToEmail: new FormControl<string>('', [
                Validators.required,
                Validators.email,
            ]),
            deliveryFromName: new FormControl<string>(''),
            deliveryMessage: new FormControl<string>('', [
                Validators.maxLength(this.validation.maxMessageLength),
            ]),
            quantity: new FormControl<number>(1, [
                Validators.required,
                Validators.min(1),
            ]),
            deliveryDate: new FormControl<Date>(undefined, [
                Validators.required,
            ]),
        });

        const todayDate = new Date();
        this.minDate = {
            year: todayDate.getFullYear(),
            month: todayDate.getMonth() + 1,
            day: todayDate.getDate(),
        };
        this.maxDate = {
            year: todayDate.getFullYear() + 1,
            month: todayDate.getMonth() + 1,
            day: todayDate.getDate(),
        };

        this.setInitialFormValues();
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
        this.additionalCalendarDayInfo.clear();

        this.generateDatesNextMonths(6);
    }

    get value() {
        return this.giftCardForm.get('value');
    }
    get recipientName() {
        return this.giftCardForm.get('recipientName');
    }
    get deliveryToEmail() {
        return this.giftCardForm.get('deliveryToEmail');
    }
    get deliveryFromName() {
        return this.giftCardForm.get('deliveryFromName');
    }
    get deliveryMessage() {
        return this.giftCardForm.get('deliveryMessage');
    }
    get quantity() {
        return this.giftCardForm.get('quantity');
    }
    get deliveryDate() {
        return this.giftCardForm.get('deliveryDate');
    }

    onBuyGiftCard() {
        if (this.giftCardForm.invalid) {
            return;
        }

        this.buyGiftCard.emit();
    }

    onFormValuesChange() {
        this.formDataChanged.emit({
            value: this.value.value,
            recipientName: this.recipientName.value,
            deliveryToEmail: this.deliveryToEmail.value,
            deliveryFromName: this.deliveryFromName.value,
            deliveryMessage: this.deliveryMessage.value,
            quantity: this.quantity.value,
            deliveryDate: this.deliveryDate.value,
        });
    }

    // the form values are saved in the parent in case the user dismisses the modal either by accident or to change the picture
    setInitialFormValues() {
        if (this.initialValues) {
            const {
                value,
                recipientName,
                deliveryToEmail,
                deliveryFromName,
                deliveryMessage,
                quantity,
                deliveryDate,
            } = this.initialValues;
            this.value.setValue(value);
            this.recipientName.setValue(recipientName);
            this.deliveryToEmail.setValue(deliveryToEmail);
            this.deliveryFromName.setValue(deliveryFromName);
            this.deliveryMessage.setValue(deliveryMessage);
            this.quantity.setValue(quantity);
            this.deliveryDate.setValue(deliveryDate);
            this.formattedDate =
                transformNgbDateToDayJs(deliveryDate).format('DD MMM YYYY');
        }
    }

    dateSelected(date: NgbDateStruct): void {
        this.deliveryDate.setValue(date);
        // We need to do this manually because the datepicker does not trigger the form
        this.onFormValuesChange();
    }

    onDateRangeSelected(dateRange: CalendarSelectedDates): void {
        this.selectedDate = dateRange.singleDate;
        if (this.selectedDate) {
            this.formattedDate = transformNgbDateToDayJs(
                this.selectedDate
            ).format('DD MMM YYYY');
            this.dateSelected(this.selectedDate);
        }
        this.calendarDatedActivity.closeCalendar();
    }

    generateDatesNextMonths(month): void {
        // Get the current date
        const currentDate = new Date();
        // Iterate over the next six months
        for (let i = 0; i < month; i++) {
            // Get the year and month for the current iteration
            let year = currentDate.getFullYear();
            let month = currentDate.getMonth() + i;

            // Adjust month and year if it goes beyond December
            if (month >= 12) {
                year += Math.floor(month / 12);
                month = month % 12;
            }

            // Get the number of days in the current month
            const numberOfDays = new Date(year, month + 1, 0).getDate();

            // Generate day information for each day in the current month
            for (let day = 1; day <= numberOfDays; day++) {
                // const date = new Date(year, month, day);
                const date: NgbDateStruct = {
                    year: year,
                    month: month + 1,
                    day: day,
                }; // NgbDateStruct format
                const dateAsString = createDateString(date); // Assuming you have a function to format date as string

                this.additionalCalendarDayInfo.set(dateAsString, {
                    date: date,
                    minLengthOfStay: 1,
                });
            }
        }
    }
}
