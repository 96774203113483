import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import { RewardsAndWalletErrorService } from '../rewards-and-wallet.error.service';
import { GeneralErrorService } from '@app/components/general-error/general-error.service';

export interface GiftCardHistoryDto {
    id: number;
    sendDate: string;
    giftCode: string;
    initialBalance: number;
    currentBalance: number;
    status: string;
    expiryDate: string;
    transactions: GiftCardHistoryTransactionDto[];
}

export interface GiftCardHistoryTransactionDto {
    id: number;
    giftCardId: number;
    action: 'Refunded' | 'Used' | 'Activated' | 'create';
    updatedBy: number;
    comments: string;
    value: number;
    orderItemId: string;
    createdDate: string;
    updatedDate: string;
}

export interface GiftCardHistory {
    id: number;
    sendDate: string;
    giftCode: string;
    initialBalance: number;
    currentBalance: number;
    status: string;
    expiryDate: string;
}

export interface GiftCardTransactionHistory {
    id: number;
    giftCardId: number;
    giftCode: string;
    description: string;
    updatedBy: number;
    amount: number;
    action: 'Refunded' | 'Used' | 'Activated' | 'create';
    orderItemId: string;
    createdDate: string;
    updatedDate: string;
}

export interface GiftCardBalanceAndHistory {
    giftCardBalance: number;
    giftCardHistory: GiftCardHistory[];
    transactionHistory: GiftCardTransactionHistory[];
}
@Injectable({
    providedIn: 'root',
})
export class GiftsService {
    constructor(
        private authHttpClient: AuthHttpClientNoXService,
        private rewardsAndWalletErrorService: RewardsAndWalletErrorService,
        private generalErrorService: GeneralErrorService
    ) {}

    getGiftCardBalanceAndHistory(): Observable<GiftCardBalanceAndHistory> {
        return this.authHttpClient
            .get<GiftCardHistoryDto[]>(`${getBookingUrl()}gift-cards/customer`)
            .pipe(
                map((response) => {
                    const giftCardHistory = response;
                    return {
                        giftCardBalance:
                            this.calculateGiftCardBalance(giftCardHistory),
                        giftCardHistory:
                            this.extractGiftCardHistory(giftCardHistory),
                        transactionHistory:
                            this.extractGiftCardTransactionHistory(
                                giftCardHistory
                            ),
                    };
                })
            );
    }

    extractGiftCardHistory(
        giftCardHistory: GiftCardHistoryDto[]
    ): GiftCardHistory[] {
        return giftCardHistory.map((history) => {
            return {
                id: history.id,
                sendDate: history.sendDate,
                giftCode: history.giftCode,
                initialBalance: history.initialBalance,
                currentBalance: history.currentBalance,
                status: history.status,
                expiryDate: history.expiryDate,
            };
        });
    }

    extractGiftCardTransactionHistory(
        giftCardHistory: GiftCardHistoryDto[]
    ): GiftCardTransactionHistory[] {
        const transactionHistory: GiftCardTransactionHistory[] = [];
        giftCardHistory.forEach((history) => {
            history.transactions.forEach((transaction) => {
                transactionHistory.push({
                    id: transaction.id,
                    giftCardId: transaction.giftCardId,
                    giftCode: history.giftCode,
                    description: transaction.comments,
                    action: transaction.action,
                    updatedBy: transaction.updatedBy,
                    amount: transaction.value,
                    orderItemId: transaction.orderItemId,
                    createdDate: transaction.createdDate,
                    updatedDate: transaction.updatedDate,
                });
            });
        });
        return transactionHistory;
    }

    calculateGiftCardBalance(giftCardHistory: GiftCardHistoryDto[]): number {
        return giftCardHistory.reduce(
            (balance, history) => balance + history.currentBalance,
            0
        );
    }

    addGiftCardToAccount(giftCode: string): Observable<unknown> {
        return this.authHttpClient
            .post(`${getBookingUrl()}gift-cards/claim/${giftCode}`, {})
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    private handleError(error: HttpErrorResponse): Observable<null> {
        let message = error?.error?.errorCode;
        if (this.rewardsAndWalletErrorService.isKnownErrorCode(message)) {
            message =
                this.rewardsAndWalletErrorService.getTranslatedErrorMessageFromErrorCode(
                    error.error
                );
        } else {
            message = error?.error?.message;
        }

        this.generalErrorService.showGeneralError(message, {
            showMailto: false,
        });
        return of(null);
    }
}
