import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, forkJoin, Observable, Observer } from 'rxjs';
import {
    CartDto,
    CartItemDto,
} from 'src/app/product-detail/booking-config/booking-config.model';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { UserData, UserService } from 'src/app/services/user.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { getBookingUrl } from 'src/app/utilities/url.utils';

export interface SignInRegisterResponse {
    token: string;
}

interface MergeCartsResultDto {
    resultedCart: CartDto | undefined;
    removedItems: CartItemDto[];
    mergedItems: CartItemDto[];
    removedDiscountCodes: string[];
}

@Injectable({
    providedIn: 'root',
})
export class LoginRegisterService {
    constructor(
        private userService: UserService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private eventService: EventService,
        private authHttpClient: AuthHttpClientNoXService
    ) {}

    getLoginRegisterAction(
        observer
    ):
        | Partial<Observer<SignInRegisterResponse>>
        | ((value: SignInRegisterResponse) => void) {
        return {
            next: (data: { token: string }) => {
                const subscription$ = this.userService.setIsLoggedIn(true, {
                    token: data.token,
                });
                const localCart = this.userService.getLocalCart();
                const subscription2$ = this.mergeCart(localCart?.userCartId);

                forkJoin([subscription$, subscription2$]).subscribe(
                    ([_, result]: [UserData, MergeCartsResultDto]) => {
                        this.userService.setLocalCart({
                            userCartId: result.resultedCart?.id,
                            itemCount: result.resultedCart?.itemsCount,
                        });
                        if (result.resultedCart?.itemsCount > 0) {
                            this.router.navigate(['/cart']);
                        } else {
                            this.router.navigate(['/']);
                        }
                        observer.next();
                    },
                    (error) => {
                        console.debug(
                            "Error while calling extra api's after successful" +
                                ' login' +
                                error
                        );
                        observer.error(error);
                    },
                    () => {
                        observer.complete();
                    }
                );
            },
            error: (error) => {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    error?.error?.message
                );

                this.spinner.hide();
                observer.error(error);
            },
            complete: () => {
                this.spinner.hide();
            },
        };
    }

    private mergeCart(guestCartId: string): Observable<MergeCartsResultDto> {
        return this.authHttpClient
            .post<MergeCartsResultDto, { guestCartId: string }>(
                `${getBookingUrl()}carts/merge`,
                { guestCartId }
            )
            .pipe(
                catchError((error) => {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        error?.error?.message
                    );
                    return [];
                })
            );
    }
}
