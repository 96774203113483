<div class="pdbc-activity-container">
    <div *ngFor="let offer of productDetails.dealOffers; let k = index">
        <h4 class="pdbc-activity-name">{{ offer.title }}</h4>
        <div
            class="pdbc-activity-service-option"
            *ngFor="let option of offer.dealOptions; let i = index"
        >
            <div class="pdbc-activity-service-details">
                <div class="pdbc-activity-service-name">{{ option.title }}</div>
                <div>{{ option.subTitle }}</div>
            </div>
            <md-counter
                [minValue]="option.minPax"
                [maxValue]="option.maxPaxDynamic"
                [counterValue]="getQuantity(option)"
                (valueChanged)="onQuantityChanged(option, $event, k, i)"
            />
            <div class="pdbc-activity-service-prc">
                <div class="pdbc-activity-service-price">
                    {{
                        option.sellingPrice === 0
                            ? ("FREE" | translate)
                            : (option.sellingPrice
                              | currency
                                  : currencySymbol
                                  : "symbol"
                                  : "1.0-2"
                                  : locale)
                    }}
                </div>
                <div
                    *ngIf="option.sellingPrice !== 0"
                    class="pdbc-activity-service-full-price"
                >
                    {{
                        option.crossedPrice
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </div>
            </div>
        </div>
    </div>
</div>
