<ng-container *ngIf="!onlyGiftCards">
    <h2 class="c-rd-title" *ngIf="!showGiftOnly">
        1. {{ "Reservation Details" | translate }}
    </h2>
    <ng-container *ngFor="let gst of guestsInternal; let i = index">
        <ng-container *ngIf="!gst.isGiftCard">
            <div
                class="c-rd-item-container"
                *ngFor="let guest of gst.value; let j = index"
            >
                <h3 class="c-rd-item-name">
                    <img
                        [src]="getDealCategoryIcon(guest.categoryproductId)"
                    />{{ guest.product_name }}
                </h3>
                <div class="c-rd-item-inputs">
                    <div
                        class="c-rd-item-input"
                        [ngClass]="{
                            error:
                                gst.validation.nameControl.invalid &&
                                gst.validation.nameControl.touched
                        }"
                    >
                        <label
                            for="{{ guest.guest_id }}_{{ guest.room_count }}"
                            [class.hidden]="!guest.name"
                            >{{ "Guest name" | translate }}</label
                        >
                        <input
                            #inputFocus
                            [formControl]="gst.validation.nameControl"
                            [ngModelOptions]="{ standalone: true }"
                            id="{{ guest.guest_id }}_{{ guest.room_count }}"
                            type="text"
                            [placeholder]="'Guest name' | translate"
                            ngDefaultControl
                            [(ngModel)]="guest.name"
                            (change)="guestNameChange()"
                        />
                        <div
                            *ngIf="
                                gst.validation.nameControl.invalid &&
                                gst.validation.nameControl.touched
                            "
                        >
                            <small
                                class="error-text"
                                *ngIf="
                                    gst.validation.nameControl.errors?.required
                                "
                                >Name is required.</small
                            >
                        </div>
                    </div>
                    <div class="c-rd-item-input">
                        <label
                            for="email_{{ guest.guest_id }}_{{
                                guest.room_count
                            }}"
                            [class.hidden]="!guest.email"
                            >{{
                                "Guest email address (optional)" | translate
                            }}</label
                        >
                        <input
                            type="email"
                            [placeholder]="
                                'Guest email address (optional)' | translate
                            "
                            id="email_{{ guest.guest_id }}_{{
                                guest.room_count
                            }}"
                            [ngModelOptions]="{ standalone: true }"
                            ngDefaultControl
                            [(ngModel)]="guest.email"
                        />
                    </div>
                </div>
                <div class="c-rd-item-children">
                    <ng-container
                        *ngFor="let child_range_desc of guest.child_range"
                    >
                        <ng-container
                            *ngFor="let item of child_range_desc | keyvalue"
                        >
                            <ng-container
                                *ngFor="let childrencategorycount of item.value"
                            >
                                <div class="c-rd-item-children-row">
                                    <div class="c-rd-item-children-age">
                                        {{ childrencategorycount.count + 1 }}
                                        . {{ item.key }}
                                        {{ "Age" | translate }}
                                        <span
                                            *ngIf="
                                                childrencategorycount.end_range !=
                                                0
                                            "
                                            >({{
                                                childrencategorycount.starting_range
                                            }}
                                            -{{
                                                childrencategorycount.end_range
                                            }})</span
                                        >
                                    </div>
                                    <input
                                        type="number"
                                        [ngClass]="
                                            childrencategorycount.status ==
                                            'full'
                                                ? undefined
                                                : 'empty'
                                        "
                                        [ngModelOptions]="{
                                            standalone: true
                                        }"
                                        ngDefaultControl
                                        [(ngModel)]="
                                            childrencategorycount.entered_date
                                        "
                                        (change)="guestChildDataChange()"
                                    />
                                    <div>
                                        {{ "Years old" | translate }}
                                    </div>
                                </div>
                                <div
                                    class="c-rd-item-children-error"
                                    *ngIf="
                                        childrencategorycount.status == 'empty'
                                    "
                                >
                                    {{ "Required Field" | translate }}
                                </div>
                                <div
                                    class="c-rd-item-children-error"
                                    *ngIf="
                                        childrencategorycount.entered_date !=
                                            '' &&
                                        childrencategorycount.end_range != 0 &&
                                        (childrencategorycount.status ==
                                            'invalid' ||
                                            childrencategorycount.entered_date <
                                                childrencategorycount.starting_range ||
                                            childrencategorycount.entered_date >
                                                childrencategorycount.end_range)
                                    "
                                >
                                    {{
                                        "The value is not within the specified range"
                                            | translate
                                    }}
                                    .
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                <label class="c-rd-item-remove-prc" ngDefaultControl>
                    <label class="custom-checkbox">
                        <input
                            [ngModelOptions]="{ standalone: true }"
                            ngDefaultControl
                            [(ngModel)]="guest.remove_price"
                            type="checkbox"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <span>
                        {{
                            "Remove price from booking confirmation" | translate
                        }}
                    </span>
                </label>
                <div class="c-rd-item-spcl-request">
                    <button
                        class="c-rd-item-spcl-button"
                        (click)="toggleRequest(i, j)"
                    >
                        {{ "Have a special request?" | translate }}
                    </button>
                    <textarea
                        *ngIf="specialRequestStatus['' + i + j]"
                        [placeholder]="
                            'Requests can be like consider providing a room on a high floor'
                                | translate
                        "
                        rows="2"
                        maxlength="220"
                        [ngModelOptions]="{ standalone: true }"
                        ngDefaultControl
                        [(ngModel)]="guest.guestrequest"
                        class="c-rd-item-spcl-text"
                    ></textarea>
                    <p
                        *ngIf="specialRequestStatus['' + i + j]"
                        class="c-rd-item-spcl-charremaining"
                    >
                        {{ "Characters Remaining " | translate
                        }}{{ 220 - guest.guestrequest?.length }}
                    </p>
                </div>
            </div>
        </ng-container></ng-container
    ></ng-container
>
