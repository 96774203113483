import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    EGiftAddToCartDto,
    EGiftCardDealInfo,
    EGiftCardDetailsResponseDto,
} from './egift-card.model';
import { Observable, switchMap } from 'rxjs';
import { CurrencyLanguageService } from '../shared/currency-language.service';
import { getBookingUrl, getResultsUrl } from '../utilities/url.utils';
import {
    AddCartItemDto,
    CartDto,
} from '../product-detail/booking-config/booking-config.model';
import { UserService } from '../services/user.service';
import { AuthHttpClientNoXService } from '../services/auth-http-client-noX.service';
import { PackageTypeId } from '../model/deal.model';
import { RewardsService } from '../services/rewards.service';
import {
    GIFT_CARD_CATEGORY_ID,
    GIFT_CARD_DEAL_ID,
    GIFT_CARD_OUTLET_ID,
} from '@app/utilities/constants';

@Injectable({
    providedIn: 'root',
})
export class EgiftCardService {
    customerId: string;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private authHttpClient: AuthHttpClientNoXService,
        private rewardsService: RewardsService
    ) {
        const customerData = localStorage.getItem('marideal-pwa_user');
        if (customerData) {
            this.customerId = JSON.parse(customerData).data.id;
        }
    }

    getRewardsPoints(value: number): Observable<number> {
        return this.rewardsService.getRewardPoints(
            value,
            this.currencyLanguageService.getLocaleDataImmediate()
        );
    }

    getEGiftDetails(): Observable<EGiftCardDetailsResponseDto> {
        return this.currencyLanguageService.getLocaleData().pipe(
            switchMap((localeData) => {
                return this.httpClient.get<EGiftCardDetailsResponseDto>(
                    `${getResultsUrl()}gift-cards?lang=${
                        localeData.language
                    }&cc=${localeData.country}`
                );
            })
        );
    }

    uploadGiftCardImage(fd: FormData) {
        // this has nothing until the backend is implemented
    }

    addToCartV2(productData: EGiftAddToCartDto): Observable<CartDto> {
        const apiUrl = this.userService.isLoggedIn
            ? `${getBookingUrl()}carts/items`
            : `${getBookingUrl()}carts/items/as-guest`;
        const localCart = this.userService.getLocalCart();
        // if there is a user cart use that, else use the local cart
        const cartId = localCart?.userCartId;
        const { currencyId } =
            this.currencyLanguageService.getLocaleDataImmediate();

        const price = productData.amount * productData.qty;

        const postBody: AddCartItemDto = {
            cartId,
            outletId: GIFT_CARD_OUTLET_ID,
            dealId: GIFT_CARD_DEAL_ID,
            dealCategoryId: GIFT_CARD_CATEGORY_ID,
            sectionId: productData.sectionId,
            dealTitle: productData.giftcard_template_image,
            rewardPointsEarned: productData.reward_points_eanred,
            dealSubtitle: '',
            quantity: productData.qty,
            subtotal: price,
            purchaseCurrencyId: currencyId,
            grandTotal: price,
            dealInfo: this.createEGCDealInfo(productData, currencyId),
        };

        return this.authHttpClient.post<CartDto, AddCartItemDto>(
            apiUrl,
            postBody
        );
    }

    private createEGCDealInfo(
        productData: EGiftAddToCartDto,
        currencyId: 1 | 2
    ): string {
        const dealInfo: EGiftCardDealInfo = {
            templateId: productData.giftcard_template_id,
            currencyId,
            amount: productData.amount,
            sendDate: productData.day_to_send,
            sendNotice: productData.notify_success,
            expiryDate: productData.expiry_date,
            buyerName: productData.customer_name,
            specialMessage: productData.message,
            recipientName: productData.recipient_name,
            recipientEmail: productData.recipient_email,
            packageTypeId: PackageTypeId.NON_CALENDAR,
            dealOptionId: 4,
        };

        return JSON.stringify(dealInfo);
    }
}
