import {
    Component,
    Input,
    Output,
    EventEmitter,
    Optional,
    ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'md-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent {
    @Input() @Optional() counterValue = 0;
    @Input() minValue = 0;
    @Input() maxValue = 100;
    @Input() disablePlus = false;

    @Output() valueChanged = new EventEmitter<number>();

    increase() {
        if (this.counterValue < this.maxValue) {
            this.counterValue++;
            this.valueChanged.emit(this.counterValue);
        }
    }

    decrease() {
        if (this.counterValue > this.minValue) {
            this.counterValue--;
            this.valueChanged.emit(this.counterValue);
        }
    }

    resetCounter() {
        this.counterValue = 0;
        this.valueChanged.emit(this.counterValue);
    }
}
