<script>
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "2553814491384451");
    fbq("track", "PageView");
</script>
<noscript
    ><img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=2553814491384451&ev=PageView&noscript=1"
/></noscript>

<div class="cko-page">
    <form class="cko-page-inner">
        <md-checkout-loading
            [showCartSkeleton]="
                showCartSkeleton ||
                checkoutUtilsService.showGetPaymentMethodSkeleton
            "
        />
        <ng-container
            *ngIf="
                checkoutData &&
                checkoutData.cartItems &&
                checkoutData.cartItems.length > 0
            "
        >
            <md-order-summary
                *ngIf="!showCartSkeleton"
                [cartItems]="cartItemsWithMaxQty"
                [cart_total_amount]="checkoutData.totalBeforeDiscounts"
                [coupon_code_discount]="checkoutData.totalDiscount"
                [discountCodeTitle]="discountCodeTitle"
                [totalPrice]="checkoutData.orderTotal"
                [desktopOnly]="true"
                [removeCartItem]="removeCartItem"
                [changeQuantity]="changeQuantity"
                [uncheckGiftAndApplyStoreCredit]="
                    uncheckGiftAndApplyStoreCredit
                "
                [giftDiscount]="checkoutData.giftDiscount"
                [storeCreditDiscount]="checkoutData.storeCreditAmount"
                [rewardPointDiscount]="checkoutData.rewardPointsDiscountAmount"
                [rewardPointsEarned]="checkoutData.rewardPointsEarned" />

            <div
                class="cko-page-left"
                *ngIf="!showPaymentMethods && checkoutData.cartItems.length > 0"
            >
                <md-reservation-details [guests]="guests" />
                <ng-container *ngTemplateOutlet="rewards"></ng-container>
                <md-booking-summary
                    mdMobileOnly
                    [cart_total_amount]="checkoutData.totalBeforeDiscounts"
                    [coupon_code_discount]="checkoutData.totalDiscount"
                    [discountCodeTitle]="discountCodeTitle"
                    [totalPrice]="checkoutData.orderTotal"
                    [standalone]="true"
                    [giftDiscount]="checkoutData.giftDiscount"
                    [storeCreditDiscount]="checkoutData.storeCreditAmount"
                    [rewardPointDiscount]="
                        checkoutData.rewardPointsDiscountAmount
                    "
                    [rewardPointsEarned]="checkoutData.rewardPointsEarned"
                ></md-booking-summary>
                <button
                    *ngIf="checkoutData.orderTotal > 0"
                    class="chk-proceed-btn"
                    [ngClass]="{
                        'safari-margin-bottom': isSafari || isFirefox
                    }"
                    [disabled]="
                        !checkoutUtilsService.isGuestNameFilled ||
                        !checkoutUtilsService.areChildrenAgesValid
                    "
                    mdMainButton="light"
                    (click)="openModal(paymentModal)"
                >
                    {{ "Proceed to payment" | translate }}
                </button>
                <button
                    *ngIf="checkoutData.orderTotal <= 0"
                    class="chk-proceed-btn"
                    mdMainButton="light"
                    id="addtocart-btn"
                    (click)="openModal(checkboxesModal)"
                >
                    {{ "Complete Order" | translate }}
                </button>
            </div>
            <div class="cko-page-left" *ngIf="showPaymentMethods">
                <ng-container *ngTemplateOutlet="payment"></ng-container></div
        ></ng-container>
        <md-empty-cart
            *ngIf="
                !showCartSkeleton &&
                !checkoutUtilsService.showGetPaymentMethodSkeleton
            "
            [items]="checkoutData?.cartItems"
        />
    </form>
</div>
<ng-template #rewards>
    <h4 class="cko-rewards-title">
        2.{{
            " Add promo code, rewards, store credit, or gift card" | translate
        }}
    </h4>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="false">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/discount_code.svg" />
                    {{ "Apply Discount Code" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                [(ngModel)]="discountCode"
                                [ngModelOptions]="{ standalone: true }"
                                ngDefaultControl
                                [disabled]="isDiscountCodeApplied"
                                [placeholder]="
                                    'Enter discount code' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                (click)="applyDiscountCode(discountCode)"
                                class="ripleEffect chk-rewards-item-dc-btn"
                                [disabled]="discountCode?.length < 1"
                            >
                                {{
                                    (isDiscountCodeApplied
                                        ? "Cancel Coupon"
                                        : "Apply Discount Code"
                                    ) | translate
                                }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="!rewardPointsBalance">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/Reward Points.svg" />
                    {{ "Spend Your Points" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-item-syp-subtitle">
                            {{ "You have " | translate }}
                            <b
                                >{{
                                    rewardPointsBalance -
                                        checkoutData?.rewardPointsSpent
                                }}{{ " reward points " }}</b
                            >
                            {{ "on your account" | translate }}
                        </p>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="number"
                                [value]="
                                    checkoutData?.rewardPointsSpent > 0
                                        ? checkoutData?.rewardPointsSpent
                                        : ''
                                "
                                ngDefaultControl
                                [disabled]="checkoutData.rewardPointsSpent > 0"
                                [(ngModel)]="checkout.rewardPointsToApply"
                                [placeholder]="
                                    'Enter your reward points' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                [disabled]="!checkout.rewardPointsToApply"
                                class="ripleEffect chk-rewards-item-dc-btn"
                                (click)="applyRewardPoints()"
                            >
                                {{
                                    areRewardPointsApplied
                                        ? ("Remove reward points" | translate)
                                        : ("Apply reward points" | translate)
                                }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="!storeCreditBalance">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/Store credit.svg" />
                    {{ "Apply Store Credit" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-asc-p">
                            {{
                                ("Available store credit: " | translate) +
                                    (storeCreditBalance -
                                        (checkoutData.storeCreditAmount || 0)
                                        | currency
                                            : localeData.currency
                                            : "symbol"
                                            : "1.0-0"
                                            : localeData.localeForCurrency)
                            }}
                        </p>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                [disabled]="checkoutData.storeCreditAmount > 0"
                                [(ngModel)]="checkout.storeCreditToApply"
                                [value]="
                                    checkoutData.storeCreditAmount > 0
                                        ? checkoutData.storeCreditAmount
                                        : ''
                                "
                                [placeholder]="
                                    'Enter store credit amount' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                (click)="applyStoreCredit()"
                                [disabled]="!checkout?.storeCreditToApply"
                                class="ripleEffect chk-rewards-item-dc-btn"
                            >
                                {{
                                    (!checkoutData.storeCreditAmount
                                        ? "Apply Store Credit"
                                        : "Remove Store Credit"
                                    ) | translate
                                }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div ngbAccordion class="chk-rewards-item-container">
        <div ngbAccordionItem [collapsed]="!(giftcardbalance > 0)">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                    <img src="assets/icons/checkout/Gift card.svg" />
                    {{ "Use Gift Card" | translate }}
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <p class="chk-rewards-item-gc-p">
                            {{ "You have " | translate
                            }}<b
                                >{{
                                    giftcardbalance
                                        | currency
                                            : localeData.currency
                                            : "symbol"
                                            : "1.0-0"
                                            : localeData.localeForCurrency
                                }}{{ " balance" | translate }}</b
                            >{{ " on your Gift Card account" }}
                        </p>
                        <div>
                            <div
                                class="chk-rewards-gc-applied"
                                *ngFor="
                                    let giftCode of checkoutData.appliedGiftCardCodes
                                "
                            >
                                {{ giftCode }}

                                <ion-icon
                                    name="close-outline"
                                    (click)="removeGiftCardDiscount(giftCode)"
                                ></ion-icon>
                            </div>
                        </div>
                        <div class="chk-rewards-item-inputs">
                            <input
                                class="chk-rewards-item-dc-input"
                                type="text"
                                [ngModelOptions]="{ standalone: true }"
                                ngDefaultControl
                                [(ngModel)]="checkout.giftCode"
                                id="enter_giftcard"
                                [placeholder]="
                                    'Enter your gift Code' | translate
                                "
                            />
                            <button
                                mdMainButton="light"
                                [disabled]="!checkout.giftCode"
                                class="ripleEffect chk-rewards-item-dc-btn fit-content"
                                (click)="applyGiftCard()"
                            >
                                {{ "Apply Gift Card" | translate }}
                            </button>
                        </div>
                        <div class="chk-rewards-item-middle">
                            <button
                                mdMainButton="light"
                                class="ripleEffect chk-rewards-item-dc-btn fit-content"
                                (click)="goToGiftCardManagement()"
                            >
                                {{ "Add new Gift card" | translate }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #checkboxes>
    <label class="cko-checkboxes-label">
        <label class="custom-checkbox">
            <input
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
                value="i_read"
                ngDefaultControl
                [(ngModel)]="checkout.termsAndConditions"
            />
            <span class="checkmark"></span>
        </label>
        <span
            >{{ "I have read and agree to the" | translate
            }}<span> {{ "terms and conditions" | translate }}</span></span
        >
    </label>
    <label class="cko-checkboxes-label">
        <label class="custom-checkbox">
            <input
                type="checkbox"
                value="i_am_mauritian"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [(ngModel)]="checkout.iAmMauritian"
            />
            <span class="checkmark"></span>
        </label>
        <span>{{
            reunionGeneral
                ? "Confirm that I am a Reunionese Citizen or Resident"
                : ("Confirm that I am a Mauritian Citizen or Resident"
                  | translate)
        }}</span>
    </label>
</ng-template>
<ng-template #payment>
    <md-booking-summary
        *ngIf="checkoutData"
        [cart_total_amount]="checkoutData.totalBeforeDiscounts"
        [coupon_code_discount]="checkoutData.totalDiscount"
        [discountCodeTitle]="discountCodeTitle"
        [totalPrice]="checkoutData.orderTotal"
        [rewardPointsEarned]="checkoutData.rewardPointsEarned"
        [desktopOnly]="false"
        [standalone]="true"
    />
    <div *ngFor="let payment of paymentList">
        <ng-container
            *ngIf="
                checkoutData &&
                checkoutData.orderTotal > 0 &&
                checkoutUtilsService.isGuestNameFilled
            "
        >
            <div
                class="cko-payment-option"
                [ngClass]="{
                    open:
                        checkout.selectedPaymentMethodId ==
                        payment.payment_method_id
                }"
                [id]="'pmid' + payment.payment_method_id"
            >
                <label>
                    <input
                        type="radio"
                        ngDefaultControl
                        [value]="payment.payment_method_id"
                        (click)="selectPaymentMethod(payment)"
                        name="paymentRadioGroup"
                    />
                    <img
                        [src]="
                            getImageSourceBasedOnCode(
                                payment.payment_method_code
                            )
                        "
                    />
                    <span *ngIf="payment.payment_method_code === 'banktransfer'"
                        >Internet Banking OR Juice</span
                    >
                    <span
                        *ngIf="payment.payment_method_code === 'cashondelivery'"
                        >Direct Bank Deposit</span
                    >
                    <span
                        *ngIf="
                            payment.payment_method_code ===
                            'mipspaymentsystemiframe'
                        "
                        >Card, POP, MyT</span
                    ></label
                >
            </div>
            <div
                style="height: 600px"
                class="cko-payment-option-value"
                *ngIf="
                    payment.payment_method_code == 'mipspaymentsystemiframe' &&
                    checkout.selectedPaymentMethodId == 9
                "
            >
                <iframe
                    id="mipsIframe"
                    [src]="mipsIframeUrl"
                    class="cko-payment-card-iframe"
                    frameborder="no"
                    scrolling="no"
                ></iframe>
                <md-mips-loading *ngIf="mipsIframeLoading" />
            </div>
            <div
                class="cko-payment-option-value direct"
                *ngIf="
                    payment.payment_method_code == 'banktransfer' &&
                    checkout.selectedPaymentMethodId == 5
                "
            >
                <ng-container
                    *ngTemplateOutlet="asyncPaymentMethod"
                ></ng-container>
            </div>
            <div
                class="cko-payment-option-value direct"
                *ngIf="
                    payment.payment_method_code == 'cashondelivery' &&
                    checkout.selectedPaymentMethodId == 1
                "
            >
                <ng-container
                    *ngTemplateOutlet="asyncPaymentMethod"
                ></ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #asyncPaymentMethod>
    <p class="cko-apm-text">
        MariDeal operates on a first come first serve basis. Limited-time offers
        can sell out at any time. Offer will remain pending in your cart for 48
        hours before cancellation if payment is not confirmed.
    </p>
    <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
    <button
        [disabled]="!checkout.termsAndConditions || !checkout.iAmMauritian"
        mdMainButton="light"
        id="addtocart-btn"
        class="ripleEffect cko-payment-option-btn"
        (click)="placeOrderNoPayment()"
    >
        {{ "Place Order" | translate }}
    </button>
</ng-template>

<md-modal-offcanvas
    #checkboxesModal
    [mobileModalTitle]="
        'Are you sure you want to complete this order?' | translate
    "
>
    <div class="cko-checkboxes-container" actualControl>
        <ng-container *ngTemplateOutlet="checkboxes"></ng-container>
        <button
            [disabled]="!checkout.termsAndConditions || !checkout.iAmMauritian"
            class="chk-proceed-btn"
            mdMainButton="light"
            id="addtocart-btn"
            (click)="placeOrderNoPayment()"
        >
            {{ "Complete Order" | translate }}
        </button>
    </div>
</md-modal-offcanvas>
<md-modal-offcanvas
    #paymentModal
    [mobileModalTitle]="'Payment methods' | translate"
>
    <ng-container actualControl *ngTemplateOutlet="payment"></ng-container>
</md-modal-offcanvas>
