<div class="pdbc-container">
    <h5
        class="pdbc-instructions"
        *ngIf="bookingConfigVariant !== 'hotel'"
    >
        {{ "Choose your options" | translate }}
    </h5>
    <md-hotel-booking
        #hotelBooking
        *ngIf="bookingConfigVariant === 'hotel'"
        [productDetailsInput]="productDetailsInternal"
        [initialBookingData]="initialBookingData"
        (bookingConfigured)="onBookingConfigured($event)"
    >
        <md-subtotal
            summaryControl
            [condensed]="true"
            [price]="bookingData.totalPrice"
            [fullPrice]="bookingData.totalFullPrice"
        />
    </md-hotel-booking>
    <div
        *ngIf="bookingConfigVariant === 'hotel'"
        class="pdbc-desktop-divider"
    ></div>
    <md-activity-booking
        *ngIf="bookingConfigVariant === 'activity'"
        [productDetails]="productDetailsInternal"
        (bookingConfigured)="onBookingConfigured($event)"
    />
    <div
        *ngIf="bookingConfigVariant === 'activity'"
        class="pdbc-desktop-divider"
    ></div>
    <md-spa-booking
        *ngIf="bookingConfigVariant === 'spa'"
        [productDetails]="productDetailsInternal"
        (bookingConfigured)="onBookingConfigured($event)"
    />
    <div
        *ngIf="bookingConfigVariant === 'spa'"
        class="pdbc-desktop-divider"
    ></div>
    <md-dated-activity-booking
        #datedActivity
        *ngIf="bookingConfigVariant === 'datedActivity'"
        [productDetails]="productDetailsInternal"
        (bookingConfigured)="onBookingConfigured($event)"
    />
    <div
        *ngIf="bookingConfigVariant === 'datedActivity'"
        class="pdbc-desktop-divider"
    ></div>
    <md-add-ons
        [productDetails]="productDetailsInternal"
        (addonConfigChanged)="onAddonConfigChanged($event)"
    />
    <ng-container *ngIf="bookingData?.flightPrice">
        <div class="pdbc-mobile-divider"></div>
        <div class="pdbc-flight-price">
            <span class="pdbc-flight-price-name">{{
                "Flight price" | translate
                }}</span>
            <span class="pdbc-flight-price-value">{{
                bookingData.flightPrice
                | currency : currencySymbol : "symbol" : "1.0-2" : locale
                }}</span>
        </div>
        <div class="pdbc-divider"></div>
    </ng-container>
    <md-subtotal
        [price]="bookingData.totalPrice"
        [fullPrice]="bookingData.totalFullPrice"
    />
    <div class="pdbc-desktop-divider"></div>
    <div style="display: flex; flex-direction: column">
        <button
            class="pdbc-button-cart"
            mdMainButton="dark"
            mdIconSize="large"
            (click)="onAddToCartClickedExternal()"
        >
            <ion-icon name="cart-outline"></ion-icon>
            {{ "ADD TO CART" | translate }}
        </button>
        <button
            class="pdbc-button-book-now"
            mdMainButton="light"
            mdIconSize="large"
            (click)="onBookNowClicked()"
        >
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            {{ "BOOK NOW" | translate }}
        </button>
    </div>
    <div class="pdbc-desktop-divider"></div>
    <md-rewards
        [rewardBadge]="productDetailsInternal.badge"
        [rewardPoints]="rewardsPoints"
    />
</div>
<div mdMobileOnly>
    <div class="pdbc-mobile-subtotal">
        <md-subtotal
            [condensed]="true"
            [price]="bookingData.totalPrice"
            [fullPrice]="bookingData.totalFullPrice"
        />
        <div class="pdbc-mobile-book-now">
            <button
                mdMainButton="light"
                mdIconSize="large"
                (click)="onBookNowClicked()"
            >
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                {{ "BOOK NOW" | translate }}
            </button>
        </div>
    </div>
</div>