import { Injectable } from '@angular/core';
import { SocialLogin } from '../../social-login/model/social-login.model';
import { Observable } from 'rxjs';
import { AuthHttpClientService } from 'src/app/services/auth-http-client.service';
import { getAuthUrl } from 'src/app/utilities/url.utils';
import {
    LoginRegisterService,
    SignInRegisterResponse,
} from './login-register.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';

interface SocialSigninBody {
    orgId: 1;
    email: string;
    countryCode: string;
    language: 'en' | 'fr';
    firstName: string;
    lastName: string;
    loginType: 'FACEBOOK' | 'GOOGLE';
    loginId: string;
    createdBy: 'md-storefront';
}

@Injectable({
    providedIn: 'root',
})
export class SocialService {
    constructor(
        private authHttpClient: AuthHttpClientService,
        private currencyLanguageService: CurrencyLanguageService,
        private loginRegisterService: LoginRegisterService
    ) {}

    socialLoginApi(data: SocialLogin): Observable<void> {
        return new Observable((observer) => {
            const { country, language } =
                this.currencyLanguageService.getLocaleDataImmediate();
            const truncatedLoginId = (data.idToken || data.id).slice(0, 255);
            const subscription$ = this.authHttpClient
                .postNotLoggedIn<SignInRegisterResponse, SocialSigninBody>(
                    `${getAuthUrl()}social-sign-in`,
                    {
                        orgId: 1,
                        email: data.email,
                        countryCode: country,
                        language,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        loginId: truncatedLoginId,
                        loginType: data.provider as 'FACEBOOK' | 'GOOGLE',
                        createdBy: 'md-storefront',
                    }
                )
                .subscribe(
                    this.loginRegisterService.getLoginRegisterAction(observer)
                );

            return () => {
                subscription$.unsubscribe();
            };
        });
    }
}
