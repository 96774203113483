import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { DealDto, DealOffer, DealOfferOption } from 'src/app/model/deal.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
} from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { CounterComponent } from 'src/app/controls/counter/counter.component';

@Component({
    selector: 'md-activity-booking',
    templateUrl: './activity-booking.component.html',
    styleUrls: ['./activity-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityBookingComponent implements OnInit, OnChanges {
    @Input() productDetails: DealDto;
    @Input() initialBookingData: MinimalProductBookingData;
    @Input() set totalAllotment(value: number) {
        this.totalAllotmentInternal = value;
    }

    totalAllotmentInternal = -1;
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    selectedDealOptionQuantities: Map<number, number> = new Map();

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @Output() quantityChanged = new EventEmitter<DealOffer[]>();
    @ViewChildren(CounterComponent) counters!: QueryList<CounterComponent>;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService
    ) { }

    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });

        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setDealOffersDefaultQty(changes);
    }

    getQuantity(option: DealOfferOption): number {
        const value = this.selectedDealOptionQuantities.get(
            option.dealOptionId
        );
        return value ? value : 0;
    }

    private setDealOffersDefaultQty(changes: SimpleChanges) {
        if (!changes['productDetails'].currentValue) return;

        if (!this.productDetails.dealOffers.length) return;

        // On first load only
        if (changes['productDetails'].previousValue !== undefined) return;

        this.productDetails.dealOffers.forEach((offer) =>
            offer.dealOptions.forEach((option) => {
                this.selectedDealOptionQuantities.set(
                    option.dealOptionId,
                    option.minPax
                );
            })
        );

        this.emitBookingConfigured();
    }

    onQuantityChanged(
        option: DealOfferOption,
        quantity: number,
        categoryIndex: number,
        optionIndex: number
    ) {
        const isSameCategoryId = this.checkSameCategoryIdAndNotifyCustomer(
            option.dealOptionId,
            this.selectedDealOptionQuantities
        );
        if (!isSameCategoryId) {
            const index = this.getListIndexBasedOnCategoryAndOptionIndex(
                categoryIndex,
                optionIndex
            );
            this.counters.toArray()[index].resetCounter();
            return;
        }
        this.setSelectedServiceOptionQuantitiesOrRemoveOption(option, quantity);
        this.emitQuantityChanged();
        this.emitBookingConfigured();
    }

    private setSelectedServiceOptionQuantitiesOrRemoveOption(
        option: DealOfferOption,
        quantity: number
    ) {
        if (quantity === 0) {
            this.selectedDealOptionQuantities.delete(option.dealOptionId);
        } else {
            this.selectedDealOptionQuantities.set(
                option.dealOptionId,
                quantity
            );
        }
    }

    private getListIndexBasedOnCategoryAndOptionIndex(
        categoryIndex: number,
        optionIndex: number
    ): number {
        let index = 0;
        for (let i = 0; i < categoryIndex; i++) {
            index += this.productDetails.dealOffers[i].dealOptions.length;
        }
        index += optionIndex;
        return index;
    }

    private checkSameCategoryIdAndNotifyCustomer(
        dealOptionId: number,
        selectedDealOptionQuantities: Map<number, number>
    ): boolean {
        const selectedServiceOption = Array.from(
            selectedDealOptionQuantities.keys()
        ).find((serviceOption) => {
            const selectedOption = this.getOptionByDealOptionId(dealOptionId);
            const serviceOptionFull =
                this.getOptionByDealOptionId(serviceOption);
            return serviceOptionFull.categoryId !== selectedOption.categoryId;
        });
        if (selectedServiceOption) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                'Multiple options cannot be added to the cart simultaneously. Please add each option separately.'
            );
            return false;
        }
        return true;
    }

    private emitBookingConfigured() {
        const totalPrice = this.calculateTotalSellingPrice();
        const totalFullPrice = this.calculateTotalCrossedPrice();
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                id: this.productDetails.dealId,
                selectedDealOptionQuantities:
                    this.computeDealOptionQuantities(),
            },
        });
    }

    private getOptionByDealOptionId(dealOptionId: number): DealOfferOption {
        let selectedOption: DealOfferOption;
        this.productDetails.dealOffers.forEach((offer) =>
            offer.dealOptions.forEach((option) => {
                if (option.dealOptionId === dealOptionId) {
                    selectedOption = option;
                }
            })
        );
        return selectedOption;
    }

    private emitQuantityChanged() {
        // Apply selectedDealOptionQuantities on productDetails.dealOffers
        this.productDetails.dealOffers.forEach(
            dealOffer => dealOffer.dealOptions.forEach(
                dealOption => dealOption.qty = this.selectedDealOptionQuantities.get(dealOption.dealOptionId) ?? 0
            )
        );

        this.quantityChanged.emit(this.productDetails.dealOffers);
    }

    private computeDealOptionQuantities(): Map<DealOfferOption, number> {
        const selectedDealOptionQuantities = new Map<DealOfferOption, number>();
        this.productDetails.dealOffers.forEach((offer) =>
            offer.dealOptions.forEach((option) => {
                selectedDealOptionQuantities.set(
                    option,
                    this.selectedDealOptionQuantities.get(option.dealOptionId)
                );
            })
        );

        return selectedDealOptionQuantities;
    }

    private calculateTotalSellingPrice() {
        let totalPrice = 0;
        this.selectedDealOptionQuantities.forEach((quantity, dealOptionId) => {
            const selectedOption = this.getOptionByDealOptionId(dealOptionId);
            totalPrice += selectedOption.sellingPrice * quantity;
        });
        return totalPrice;
    }

    private calculateTotalCrossedPrice() {
        let totalFullPrice = 0;
        this.selectedDealOptionQuantities.forEach((quantity, dealOptionId) => {
            const selectedOption = this.getOptionByDealOptionId(dealOptionId);
            totalFullPrice += selectedOption.crossedPrice * quantity;
        });
        return totalFullPrice;
    }
}
