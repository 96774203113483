import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getAuthUrl } from '../utilities/url.utils';
import { CreateUserDto } from './model/register.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrencyLanguageService } from '../shared/currency-language.service';
import { AuthHttpClientService } from '../services/auth-http-client.service';
import {
    LoginRegisterService,
    SignInRegisterResponse,
} from '../login-register/shared-service/login-register.service';

interface RegisterBody {
    orgId: 1;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    dateOfBirth: string;
    countryCode: string;
    password: string;
    language: 'en' | 'fr';
    createdBy: 'md-storefront';
}

@Injectable({
    providedIn: 'root',
})
export class RegisterService {
    loginStatus!: number;

    constructor(
        private spinner: NgxSpinnerService,
        private currencyLanguageService: CurrencyLanguageService,
        private authHttpClient: AuthHttpClientService,
        private loginRegisterService: LoginRegisterService
    ) {}

    createUser(data: CreateUserDto): Observable<void> {
        this.spinner.show();
        const { language } =
            this.currencyLanguageService.getLocaleDataImmediate();
        return new Observable((observer) => {
            const subscription$ = this.authHttpClient
                .postNotLoggedIn<SignInRegisterResponse, RegisterBody>(
                    `${getAuthUrl()}create-account`,
                    {
                        orgId: 1,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phone: data.mobile.e164Number,
                        dateOfBirth: data.dob,
                        countryCode: data.country,
                        password: data.password,
                        language,
                        createdBy: 'md-storefront',
                    }
                )
                .subscribe(
                    this.loginRegisterService.getLoginRegisterAction(observer)
                );

            return () => {
                subscription$.unsubscribe();
            };
        });
    }
}
