export interface LocalStorageMaridealPwaDao {
    time: number;
    data: unknown;
}

const storage_key = 'marideal-pwa';

/**
 * Method to set cache
 * @param thisObj
 * @param cacheName
 * @param data
 */

import { RECENTLY_VIEWED_DEALS } from './local-storage.keys';
// TODO: Once the rewrite is done we'll have to go through all of these utilities and clean up

/**
 * Careful when using this since the checkin_ stuff is commented out.
 * Will have to fix those edge cases when we get to them so they are not handled here
 * @param cacheName
 * @param data
 */
export const setCache = (cacheName: string, data: unknown): void => {
    const storageName = `${storage_key}_${cacheName}`;
    const date = new Date();
    const cacheableData = JSON.stringify({ time: date.getTime(), data: data });

    localStorage.setItem(storageName, cacheableData);
};

/**
 * Method to get Cache by name
 * @param thisObj
 * @param cacheName
 */
export const getCache = (
    cacheName: string
): LocalStorageMaridealPwaDao | undefined => {
    const storageName = `${storage_key}_${cacheName}`;

    return JSON.parse(localStorage.getItem(storageName) || null);
};

/**
 * Method to remove cache
 * @param thisObj
 * @param cacheName
 */

export const removeCache = (cacheName: string): void => {
    const storageName = `${storage_key}_${cacheName}`;
    localStorage.removeItem(storageName);
    return;
};

export const setRecentEntries = (recentEntries: number[]): void => {
    const existingDeals = getRecentEntries();
    const uniqueDeals = Array.from(
        new Set(existingDeals.concat(recentEntries))
    );
    localStorage.setItem(RECENTLY_VIEWED_DEALS, JSON.stringify(uniqueDeals));
};

export const getRecentEntries = (): number[] => {
    return JSON.parse(localStorage.getItem(RECENTLY_VIEWED_DEALS) || '[]');
};
