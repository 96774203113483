<div class="pdbc-occupancy-container">
    <md-special-offers [productDetails]="productDetails" />
    <md-occupancy
        #occupancy
        [ageGroups]="productDetails.ageGroups"
        [disableAllPlus]="isMaxForSelectedOption"
    />
    <div class="max-occupancy-text">
        <div
            [hidden]="!isMaxForSelectedOption"
            translate
        >
            <ng-container *ngIf="selectedServiceOptionInternal.isHighestMaxGuest; else elseBlock">
                Maximum capacity. Add more rooms separately to cart.
            </ng-container>
            <ng-template #elseBlock>
                For higher occupancy, please select a different room.
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="visibleServiceCategoriesAndOptions.length > 0">
    <div *ngFor="let accomOffer of visibleServiceCategoriesAndOptions">
        <h3 class="pdhb-service-name">{{ accomOffer.title }}</h3>
        <label
            *ngFor="let accomOption of accomOffer.accomOptions"
            class="pdbc-service-option"
        >
            <input
                type="radio"
                name="serviceOption"
                [id]="accomOption.mealPlanId"
                [value]="accomOption"
                [(ngModel)]="selectedServiceOptionInternal"
                (click)="onServiceOptionChanged(accomOption)"
            />
            <div class="pdbc-service-option-details">
                <div class="pdbc-service-option-data">
                    <span class="pdbc-service-option-name">{{
                        accomOption.roomName
                        }}</span>
                    <span class="pdbc-service-option-occupancy">{{
                        accomOffer.subTitle
                        }}
                    </span>
                </div>
                <div class="pdbc-service-option-price">
                    {{
                    accomOption.sellingPrice
                    | currency
                    : currencySymbol
                    : "symbol"
                    : "1.0-2"
                    : locale
                    }}
                </div>
            </div>
        </label>
    </div>
</div>
<div
    *ngIf="!(visibleServiceCategoriesAndOptions?.length > 0)"
    class="error-message-container"
>
    <span
        class="error-message"
        translate
    >
        accomOptionErrors.no-matching-rooms
    </span>
</div>
<div
    mdMobileOnly
    *ngIf="selectedServiceOptionInternal"
    (click)="!calendarErrorMessage && onDatesClick()"
>
    <div
        *ngIf="calendarErrorMessage"
        class="error-message-container"
    >
        <div class="error-message">
            {{ calendarErrorMessage }}
        </div>
    </div>
    <div
        *ngIf="!calendarErrorMessage"
        class="pdbc-mobile-dates-container"
    >
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Check-in" | translate }}
            </div>
            <div
                class="pdbc-mobile-dates-content empty"
                *ngIf="!fromDate"
            >
                -
            </div>
            <div
                class="pdbc-mobile-dates-content"
                *ngIf="fromDate"
            >
                {{ fromDate.format("DD MMM, YYYY") }}
            </div>
        </div>
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Check-out" | translate }}
            </div>
            <div
                class="pdbc-mobile-dates-content empty"
                *ngIf="!toDate"
            >-</div>
            <div
                class="pdbc-mobile-dates-content"
                *ngIf="toDate"
            >
                {{ toDate.format("DD MMM, YYYY") }}
            </div>
        </div>
        <div class="pdbc-mobile-dates-split">
            <div class="pdbc-mobile-dates-title">
                {{ "Night(s)" | translate }}
            </div>
            <div class="pdbc-mobile-dates-content">{{ totalNights }}</div>
        </div>
    </div>
</div>
<div mdDesktopOnly>
    <div class="calendar-container">
        <md-calendar
            #calendarHotel
            [infoForEachDay]="additionalCalendarDayInfo"
            [strictDates]="isTravel"
            (dateRangeSelected)="onDateRangeSelected($event)"
            [errorMessage]="calendarErrorMessage"
        ><ng-content
                summaryControl
                select="[summaryControl]"
            ></ng-content></md-calendar>
    </div>
</div>