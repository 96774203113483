import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import {
    NgbCalendar,
    NgbDate,
    NgbDateStruct,
    NgbDatepicker,
    NgbOffcanvas,
    NgbPopover,
} from '@ng-bootstrap/ng-bootstrap';
import {
    addDaysToDateStruct,
    createDateString,
    transformNgbDateToDayJs,
} from './calendar.utils';
import { screenSizes } from 'src/app/utilities/theme';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

export interface CalendarDayInfo {
    date: NgbDateStruct;
    tooltipContent?: string;
    minLengthOfStay: number;
}

export interface CalendarDiscoutInfo {
    minNights: number;
    discountPercentage: number;
}

export interface CalendarSelectedDates {
    fromDate?: NgbDate;
    toDate?: NgbDate;
    totalNights?: number;
    singleDate?: NgbDate;
}

@Component({
    selector: 'md-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
    @Input() isSingleDate = false;
    @Input() infoForEachDay?: Map<string, CalendarDayInfo>;
    @Input() showDesktopInPopup = false;
    @Input() startDate: NgbDate;
    @Input() discounts: CalendarDiscoutInfo[] = [];
    @Input() strictDates = false;

    private _errorMessage = '';
    @Input()
    @Optional()
    set errorMessage(value: string) {
        this._errorMessage = value;
        this.disableTooltip = !!value;
        this.enableFocus = !value;
        this.enableHoveredDate = !value;
        this.setHostError = !!value;
    }
    get errorMessage(): string {
        return this._errorMessage;
    }

    hoveredDate: NgbDate | null = null;
    singleDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    formattedFromDate: string;
    formattedToDate: string;
    formattedSingleDate: string;
    totalNights: number;
    totalMonths: number;
    showSingleMonth = true;
    hasRenderedAsMobile = false;
    isInlineCalendarVisible = false;
    desktopTitle = 'Select Dates';
    desktopSubtitle = 'Minimum 1 night stay';

    // Related to errorMessage
    disableTooltip = false;
    enableFocus = true;
    enableHoveredDate = true;

    private internalInfoForEachDay: Map<string, CalendarDayInfo> = new Map();

    @Output() dateRangeSelected = new EventEmitter<CalendarSelectedDates>();

    @ViewChild('mobileCalendar') mobileCalendar: NgbCalendar;
    @ViewChild('p') popover: NgbPopover;

    @HostBinding('class.md-calendar-host-error-message') setHostError = false;

    constructor(
        private offcanvasService: NgbOffcanvas,
        private translate: TranslateService,
        private ref: ChangeDetectorRef
    ) { }

    refreshCalendarWithoutOpen(): void {
        this.sortInfoForEachDay();
        this.internalInfoForEachDay = new Map(this.infoForEachDay);
        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;

        if (this.hasRenderedAsMobile) {
            this.showSingleMonth = false;
        } else {
            if (!this.popover) {
                this.isInlineCalendarVisible = true;
            }

            this.showSingleMonth = true;
        }

        this.resetDesktopHeaderText();
        this.setUpForMultipleMonths();

        this.ref.detectChanges();
    }

    openCalendar(): void {
        this.setUpForFirstUse(this.popover);
    }

    closeCalendar(): void {
        this.offcanvasService.dismiss();
        if (this.popover) {
            this.popover.close();
        } else {
            this.isInlineCalendarVisible = false;
        }
    }

    private sortInfoForEachDay(): void {
        this.infoForEachDay = new Map(
            Array.from(this.infoForEachDay).sort((a, b) => {
                const dateA = transformNgbDateToDayJs(a[1].date);
                const dateB = transformNgbDateToDayJs(b[1].date);
                return dateA.diff(dateB, 'day');
            })
        );
    }

    private setUpForFirstUse(target?: NgbPopover): void {
        this.sortInfoForEachDay();
        this.internalInfoForEachDay = new Map(this.infoForEachDay);
        this.hasRenderedAsMobile = window.innerWidth < screenSizes.mobile;
        // if screen size is mobile, open the offcanvasService
        if (this.hasRenderedAsMobile) {
            this.offcanvasService.open(this.mobileCalendar, {
                position: 'bottom',
                panelClass: 'bc-mobile-calendar',
                scroll: true,
            });
            this.showSingleMonth = false;
        } else {
            // if there's a target it means it's opened in a popup
            if (target) {
                target.open();
            } else {
                this.isInlineCalendarVisible = true;
            }
            this.showSingleMonth = true;
        }

        this.resetDesktopHeaderText();
        this.setUpForMultipleMonths();

        this.ref.detectChanges();
    }

    private setUpForMultipleMonths(): void {
        // TODO: this logic can be simplified
        if (!this.showSingleMonth) {
            if (this.showDesktopInPopup && !this.hasRenderedAsMobile) {
                this.totalMonths = 2;
                return;
            }
            const infoAsArray = Array.from(this.infoForEachDay.values());
            const length = infoAsArray.length;

            if (!length) {
                this.totalMonths = 2;
                return;
            }

            const lastDate = transformNgbDateToDayJs(
                infoAsArray[length - 1].date
            );

            const today = dayjs();
            // here we need to account for 2 things:
            // 1. if the last date is earlier during its month than today is durring the current month we need to show an extra month
            // 2. we need to add another month because the diff would just say the difference in months and we need to include the current month
            this.totalMonths = lastDate.diff(today, 'month') + 2;
        }
    }

    navigate(datepicker: NgbDatepicker, number: number) {
        const { state, calendar } = datepicker;
        datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
    }

    getTooltip(date: NgbDateStruct): string {
        const tooltipText = this.internalInfoForEachDay.get(
            createDateString(date)
        )?.tooltipContent;
        return tooltipText ? tooltipText : '';
    }

    onDateSelection(date: NgbDate) {
        if (this.isSingleDate) {
            this.onSingleDateSelected(date);
        } else {
            this.onDateRangeSelected(date);
        }
    }

    stopPropagationIfDisabled(event) {
        if (event.target.classList.contains('disabled')) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    clearDateSelection() {
        this.singleDate = null;
        this.fromDate = null;
        this.toDate = null;
        this.removeSelectionSummary();
        this.setExternallyDisabledDates();
        this.dateRangeSelected.emit({
            fromDate: undefined,
            toDate: undefined,
            singleDate: undefined,
        });
    }

    isHovered(date: NgbDate) {
        return (
            !this.isSingleDate &&
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        return (
            !this.isSingleDate &&
            this.toDate &&
            date.after(this.fromDate) &&
            date.before(this.toDate)
        );
    }

    isRange(date: NgbDate) {
        return (
            (!this.isSingleDate && date.equals(this.fromDate)) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    isDisabledDate(date: NgbDate): boolean {
        if (this.isFromOrToDate(date)) {
            return false;
        }

        const disabled = !this.internalInfoForEachDay.get(
            createDateString(date)
        );
        if (disabled) {
            return true;
        }

        const disabledBecauseOfStrictDates = this.disableOnStrictDates(date);
        if (disabledBecauseOfStrictDates) {
            return true;
        }

        const disabledBecauseOfMinimumStay =
            this.disableIfMinimumStayNotPossible(date);
        if (disabledBecauseOfMinimumStay) {
            return true;
        }

        return disabled;
    }

    private isFromOrToDate(date: NgbDate): boolean {
        return (
            (this.fromDate && date.equals(this.fromDate)) ||
            (this.toDate && date.equals(this.toDate))
        );
    }

    disableOnStrictDates(date: NgbDate): boolean {
        if (!this.strictDates) {
            return false;
        } else {
            const internalInfoItem = this.internalInfoForEachDay.get(
                createDateString(date)
            );
            if (!internalInfoItem) {
                return true;
            }
            const { minLengthOfStay } = internalInfoItem;
            for (let i = 1; i <= minLengthOfStay; i++) {
                const dateToCheck = addDaysToDateStruct(date, i);
                if (
                    !this.internalInfoForEachDay.get(
                        createDateString(dateToCheck)
                    )
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    private disableIfMinimumStayNotPossible(date: NgbDate): boolean {
        const internalInfoItem = this.internalInfoForEachDay.get(
            createDateString(date)
        );
        if (!internalInfoItem) {
            return true;
        }
        // return false if the date is fromDate + minLengthOfStay of fromDate because we need to be
        // able to choose this as an end date
        if (this.isDateFromDatePlusMinimumStay(date)) {
            return false;
        }

        const { minLengthOfStay } = internalInfoItem;
        for (let i = 1; i < minLengthOfStay; i++) {
            const dateToCheck = addDaysToDateStruct(date, i);
            if (
                !this.internalInfoForEachDay.get(createDateString(dateToCheck))
            ) {
                return true;
            }
        }
        return false;
    }

    private isDateFromDatePlusMinimumStay(date: NgbDate): boolean {
        if (!this.fromDate) {
            return false;
        }
        const { minLengthOfStay } = this.internalInfoForEachDay.get(
            createDateString(this.fromDate)
        );
        return date.equals(addDaysToDateStruct(this.fromDate, minLengthOfStay));
    }

    onModalContinueClick() {
        this.offcanvasService.dismiss();
    }

    onModalCloseClick() {
        this.offcanvasService.dismiss();
        this.dateRangeSelected.emit({
            fromDate: undefined,
            toDate: undefined,
            singleDate: undefined,
        });
    }

    private onSingleDateSelected(date: NgbDate) {
        this.singleDate = date;
        this.formattedSingleDate =
            transformNgbDateToDayJs(date).format('DD MMM YYYY');
        this.dateRangeSelected.emit({
            singleDate: this.singleDate,
        });
    }

    private removeSelectionSummary() {
        this.formattedFromDate = undefined;
        this.formattedToDate = undefined;
        this.formattedSingleDate = undefined;
        this.totalNights = undefined;
    }

    private resetDesktopHeaderText() {
        this.desktopTitle = this.translate.instant('Select Dates');
        this.desktopSubtitle = this.translate.instant(
            'Add your travel dates for exact pricing'
        );
    }

    private setDesktopHeaderText() {
        if (this.fromDate && !this.toDate) {
            this.desktopTitle = this.translate.instant('Select Dates');
            this.desktopSubtitle = this.translate.instant(
                'Minimum stay: {{count}} nights',
                { count: this.getMinimumStayForDate(this.fromDate) }
            );
        } else if (this.fromDate && this.toDate && this.totalNights) {
            this.desktopTitle = this.translate.instant('{{count}} nights', {
                count: this.totalNights,
            });
        }
    }

    private onDateRangeSelected(date: NgbDate) {
        if (this.canSelectFromDate()) {
            this.fromDate = date;
            this.formattedFromDate =
                transformNgbDateToDayJs(date).format('DD MMM YYYY');
            const minimumStay = this.getMinimumStayForDate(date);
            this.desktopSubtitle = this.translate.instant(
                'Minimum stay: {{count}} nights',
                { count: minimumStay }
            );
            if (this.strictDates) {
                const internalInfoItem = this.internalInfoForEachDay.get(
                    createDateString(date)
                );
                if (!internalInfoItem) {
                    return;
                }
                const { minLengthOfStay } = internalInfoItem;
                const toDate = addDaysToDateStruct(date, minLengthOfStay);
                this.onDateRangeSelected(toDate);
            } else {
                this.removeAvailabilityBasedOnFromDateInfo(date);
            }
        } else if (this.canSelectToDate(date)) {
            this.toDate = date;
            const toDateDayJs = transformNgbDateToDayJs(date);
            const fromDateDayJs = transformNgbDateToDayJs(this.fromDate);
            this.formattedToDate = toDateDayJs.format('DD MMM YYYY');
            this.totalNights = toDateDayJs.diff(fromDateDayJs, 'day');
            this.dateRangeSelected.emit({
                fromDate: this.fromDate,
                toDate: this.toDate,
                totalNights: this.totalNights,
            });
            this.setExternallyDisabledDates();
        } else {
            this.removeSelectionSummary();
            this.resetDesktopHeaderText();
            this.toDate = null;
            this.fromDate = null;
            this.onDateRangeSelected(date);
        }

        this.setDesktopHeaderText();
    }

    private getMinimumStayForDate(date: NgbDateStruct): number {
        const internalInfoItem = this.internalInfoForEachDay.get(
            createDateString(date)
        );
        if (!internalInfoItem) {
            return 0;
        }

        return internalInfoItem.minLengthOfStay;
    }

    private setExternallyDisabledDates() {
        this.internalInfoForEachDay = new Map(this.infoForEachDay);
    }

    private removeAvailabilityBasedOnFromDateInfo(fromDate: NgbDate) {
        this.setExternallyDisabledDates();

        const internalInfoItemForFromDate = this.internalInfoForEachDay.get(
            createDateString(fromDate)
        );
        if (!internalInfoItemForFromDate) {
            // this should never happen because user shouldn't be able to click. Log to Sentry
            return;
        }

        const mapArray = Array.from(this.internalInfoForEachDay);

        const { minLengthOfStay } = internalInfoItemForFromDate;
        let gapInAvailabilityAfterFromDate = false;
        mapArray.forEach((entry, i) => {
            const [key, value] = entry;
            const dateToCheck = NgbDate.from(value.date);

            if (dateToCheck.before(fromDate)) {
                this.internalInfoForEachDay.delete(key);
                return;
            }

            if (
                this.isFirstGapInAvailability(
                    dateToCheck,
                    fromDate,
                    gapInAvailabilityAfterFromDate,
                    mapArray,
                    i
                )
            ) {
                // this means that there is a gap in the availability
                // remove all dates after this one
                gapInAvailabilityAfterFromDate = true;
                // add the date after the entry.date to the map because even though it cannot be selected
                // as a fromDate, it can be selected as a toDate
                this.addFromDatePlusMinimumStayToMapIfNotPresent(
                    mapArray[i - 1][1].date,
                    1
                );
            }

            if (gapInAvailabilityAfterFromDate) {
                this.internalInfoForEachDay.delete(key);
                return;
            }

            if (i === mapArray.length - 1) {
                // if there is no gap in availability after fromDate, add another day after the last one on the list
                // this effectively makes the last date bookable
                this.addAvailabilityAtTheEndOfTheList(fromDate);
            }

            const endDateDayJs = transformNgbDateToDayJs(fromDate);
            const minEndDate = endDateDayJs.add(minLengthOfStay, 'day');
            if (
                dateToCheck.after(fromDate) &&
                dateToCheck.before({
                    day: minEndDate.date(),
                    month: minEndDate.month() + 1,
                    year: minEndDate.year(),
                })
            ) {
                this.internalInfoForEachDay.delete(key);
            }
        });
    }

    private addAvailabilityAtTheEndOfTheList(fromDate: NgbDate) {
        const lastDate = Array.from(this.internalInfoForEachDay).pop()[1].date;
        const minimumStayForLastDate = this.getMinimumStayForDate(lastDate);
        if (
            (fromDate.equals(lastDate) && minimumStayForLastDate === 1) ||
            fromDate.before(lastDate)
        ) {
            this.addFromDatePlusMinimumStayToMapIfNotPresent(lastDate, 1);
        }
    }

    private addFromDatePlusMinimumStayToMapIfNotPresent(
        fromDate: NgbDateStruct,
        minLengthOfStay: number
    ): void {
        // add to internalInfoForEachDay the date that is at fromDate + minimumStay
        // if it's not in the map
        const minEndDate = addDaysToDateStruct(fromDate, minLengthOfStay);
        if (
            !this.internalInfoForEachDay.get(createDateString(minEndDate)) &&
            this.internalInfoForEachDay.get(createDateString(minEndDate)) ===
            undefined
        ) {
            this.internalInfoForEachDay.set(createDateString(minEndDate), {
                date: minEndDate,
                minLengthOfStay,
                tooltipContent: '',
            });
        }
        // then sort internalInfoForEachDay by date
        this.internalInfoForEachDay = new Map(
            Array.from(this.internalInfoForEachDay).sort((a, b) => {
                const dateA = transformNgbDateToDayJs(a[1].date);
                const dateB = transformNgbDateToDayJs(b[1].date);
                return dateA.diff(dateB, 'day');
            })
        );
    }

    private isFirstGapInAvailability(
        dateToCheck: NgbDate,
        fromDate: NgbDate,
        gapInAvailabilityFound: boolean,
        availabilityArray: [string, CalendarDayInfo][],
        indexInAvailabilityArray: number
    ): boolean {
        return (
            !gapInAvailabilityFound &&
            dateToCheck.after(fromDate) &&
            indexInAvailabilityArray > 0 &&
            dateToCheck.after(
                addDaysToDateStruct(
                    availabilityArray[indexInAvailabilityArray - 1][1].date,
                    1
                )
            )
        );
    }

    private canSelectFromDate(): boolean {
        return !this.fromDate && !this.toDate;
    }

    private canSelectToDate(date: NgbDate): boolean {
        return this.fromDate && !this.toDate && date.after(this.fromDate);
    }
}
